import { useCallback, useState } from "react";
import { GrandPrixStore } from "../../grandprix/GrandPrixStore";
import { SessionType } from "../../models/SessionType";

export interface SessionRatingProps {
    grandPrixId: number;
    sessionType: SessionType;
}

/**
 * Hook for managing session ratings and persistence
 * @param props @see SessionRatingProps
 * @returns Rating, count, and a function to manage ratings
 */
export const useSessionRating = (props: SessionRatingProps): [number, number, (rating: number) => void] => {
    const [rating, setRating] = useState(
        localStorage.getItem(`${props.grandPrixId}.${props.sessionType}`) != null ? 
            Number.parseInt(localStorage.getItem(`${props.grandPrixId}.${props.sessionType}`) as string) : 
            0);

    const [count, setCount] = useState(231); // TODO: parameterize.

    const handleRating = useCallback((rating: number) => {
        const userId = localStorage.getItem('user_id') as string;

        GrandPrixStore.putSessionRating(userId, props.grandPrixId, props.sessionType, rating)
        localStorage.setItem(`${props.grandPrixId}.${props.sessionType}`, rating.toString());
        setRating(rating);
    }, []);

    return [rating, count, handleRating];
}
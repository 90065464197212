import { useStyletron } from "baseui";
import { useMemo } from "react";
import { CssUtilities } from "../../helpers/CssUtilities";
import { GrandPrix } from "../../models/GrandPrix";
import { Score } from "../../models/Score";
import { SessionType } from "../../models/SessionType";
import GrandPrixLabel from "./GrandPrixLabel";
import SessionRatingCard from './SessionRatingCard';

export interface GrandPrixRowProps {
    grandPrix: GrandPrix;
    onSessionRatingChange: (gpId: number, sessionType: SessionType, updatedScore: number) => void;
}

export const GrandPrixRow = (props: GrandPrixRowProps) => {
    const [css, theme] = useStyletron();

    // Mapping sessions to an array
    const sessionsArr = useMemo(() => props.grandPrix.sessionRatings ?
        Array.from(props.grandPrix.sessionRatings) : [], [props.grandPrix.sessionRatings]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.colors.contentPrimary
        }}>
            <GrandPrixLabel name={props.grandPrix.displayName}
                regionCode={props.grandPrix.regionCode}
                dateStart={props.grandPrix.sessionTimes.get(SessionType.Qualifying) as Date}
                dateEnd={props.grandPrix.sessionTimes.get(SessionType.Race) as Date}
                styleOverrides={{ marginBottom: '14px' }}
                />
            <div style={{ 
                minWidth: '330px',
                width: '50%'
                }}>
                { sessionsArr.map((sessionEntry, idx) => 
                    <SessionRatingCard sessionType={sessionEntry[0]}
                        grandPrixId={props.grandPrix.id}
                        aggregatedRating={sessionEntry[1].score}
                        userRating={props.grandPrix.userRatings?.get(sessionEntry[0])}
                        count={sessionEntry[1].count}
                        onSessionRatingChange={props.onSessionRatingChange}
                        styleOverrides={CssUtilities.useBetweenListMargin(18, idx, sessionsArr.length)}/>
                    )}
            </div>
        </div>
    )
}

export default GrandPrixRow
import { LightTheme, DarkTheme, ThemeProvider } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { GrandPrix } from "../../models/GrandPrix";
import GrandPrixLabel from './GrandPrixLabel';
import SessionRatingCard from './SessionRatingCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RatingCardHeaders from './RatingCardHeaders';
import { SessionType } from '../../models/SessionType';
import GrandPrixRow from './GrandPrixRow';
import { Block } from 'baseui/block';
import GrandPrixCardMobile from './mobile/GrandPrixCardMobile';
import { Score } from '../../models/Score';

export interface ContentProps {
    grandPrixes: GrandPrix[];
    onSessionRatingChange: (gpId: number, sessionType: SessionType, updatedScore: number) => void;
}

export const ContentV2 = (props: ContentProps) => {

    const [css, theme] = useStyletron(); // Accessing universal theme

    const [cards, setCards] = useState(new Array(props.grandPrixes.length).fill(false));
    const toggleCardOpened = useCallback((targetIdx: number) => {
        const newCards = [...cards]; // shallow copy is necessary for React state diffing
        newCards[targetIdx] = !cards[targetIdx];
        return newCards;
    }, [cards]);

    useEffect(() => {
        document.body.style.backgroundColor = theme.colors.backgroundPrimary;
    }, [theme]);

    return (
        <div>
            {/* Mobile */}
            <Block display={['block', 'none']}>
                <div>
                    { props.grandPrixes.map((gp, idx) => 
                        <GrandPrixCardMobile grandPrix={gp} onSessionRatingChange={props.onSessionRatingChange}
                            toggleOpen={() => setCards(toggleCardOpened(idx))}
                            isOpened={cards[idx]}
                            styleOverrides={{ 
                                margin: '14px'
                            }}/>)}
                </div>
            </Block>
            {/* Desktop */}
            <Block display={['none', 'block']}>
                {/* Header Row */}
                <Grid overrides={{
                        GridWrapper: { style: { width: '100%' } }
                    }}>
                    <Cell span={[0, 0, 2]}></Cell>
                    <Cell span={8}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}>
                            <RatingCardHeaders styleOverrides={{ minWidth: '330px', width: '50%' }} /> 
                        </div>
                    </Cell>
                    <Cell span={[0, 0, 2]}/>
                </Grid>

                {/* Grand Prix rows */}
                <Grid
                    overrides={{
                        GridWrapper: { style: { width: '100%' } }
                    }}>
                    <Cell span={[0, 0, 2]}/>
                    <Cell span={8}> 
                            { props.grandPrixes.map((gp, idx) => 
                                <>
                                    <GrandPrixRow grandPrix={gp} onSessionRatingChange={props.onSessionRatingChange}/>
                                    { idx < props.grandPrixes.length - 1 ?
                                        // Divider
                                        <div style={{ borderTopColor: theme.colors.borderOpaque, 
                                        width: '100%', 
                                        marginTop: '16px', 
                                        marginBottom: '16px',
                                        borderTopWidth: '1px',
                                        borderTopStyle: 'solid' }}></div> : <></>}
                                </>) }
                    </Cell>
                    <Cell span={[0, 0, 2]}/>
                </Grid>
            </Block>
        </div>
    );
}

export default ContentV2;
import { useStyletron } from "baseui";
import { CSSProperties } from "react";

export interface RatingCardHeadersProps {
    styleOverrides?: CSSProperties;
}

export const RatingCardHeaders = (props: RatingCardHeadersProps) => {
    const [css, theme] = useStyletron();

    return (
        <div style={props.styleOverrides}>
            <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px'
                }}>
                <div style={{
                    width: '60px'
                }}>{/* empty-column */}</div>
                <span className={css({
                ...theme.typography.ParagraphMedium,
                color: theme.colors.contentPrimary
                })}>
                    gprating
                </span>
                <span className={css({
                ...theme.typography.ParagraphMedium,
                color: theme.colors.contentPrimary
                })}>
                    your rating
                </span>
            </div>
        </div>
    )
}

export default RatingCardHeaders;
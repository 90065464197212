import {
  HeaderNavigationOverrides,
} from "baseui/header-navigation";
import { AppNavBar } from "baseui/app-nav-bar";
import { Tag, KIND, VARIANT } from 'baseui/tag';
import GpRatingsIconLight from "./graphics/GpRatingsIconLight";
import GpRatingsIconDark from "./graphics/GpRatingsIconDark";

export interface NavigationProps {
  isDarkMode: boolean;
}

export const Navigation = (props: NavigationProps) => {
  const overrides: HeaderNavigationOverrides = {};
  return (
    <>
      <AppNavBar
        title={<div style={{
          display: 'flex',
          justifyContent: 'center'
          }}>
          <div style={{
            display: 'block',
            paddingTop: '4px',
            paddingRight: '4px'
          }}>
            {
              props.isDarkMode ? 
              <GpRatingsIconDark  style={{ width: '160px'}}/> :
              <GpRatingsIconLight  style={{ width: '160px'}}/>
            }
            
          </div>
          {/* <span style={{ paddingRight: '12px' }}>gpratings</span>
          <span style={{ paddingRight: '16px' }}>🏁</span> */}
          <Tag
            kind={KIND.yellow}
            variant={VARIANT.solid}
            closeable={false}>alpha
          </Tag>
        </div>}
        />
    </>
  );
};

export default Navigation;

import { useMemo } from "react";
import { EmojiMapper } from "../../helpers/EmojiMapper";
import { SessionType } from "../../models/SessionType";

/**
 * Given a session type, return an emoji hexcode.
 * @param sessionType session type
 * @returns Emoji hexcode for use in HTML
 */
export const useSessionEmoji = (sessionType: SessionType) => {
    const sessionEmojiHexcode = useMemo(() => {
        switch (sessionType) {
            case SessionType.Race:
                return EmojiMapper.getEmojiByCodepoint(['1F3CE', 'FE0F']);
            case SessionType.Qualifying:
                return EmojiMapper.getEmojiByCodepoint(['23F1', 'FE0F']);
            case SessionType.Sprint:
                return EmojiMapper.getEmojiByCodepoint(['1F3C3']);
        }
    }, [sessionType]);

    return sessionEmojiHexcode;
}
export class EmojiMapper {
    /**
     * Unicode representation of regional indicator symbol letters.
     * https://util.unicode.org/UnicodeJsps/list-unicodeset.jsp?a=[:Regional_Indicator=Yes:]
     */
    private static regionalIndicatorSymbolLetters = new Map([
        ['A', '1F1E6'],
        ['B', '1F1E7'],
        ['C', '1F1E8'],
        ['D', '1F1E9'],
        ['E', '1F1EA'],
        ['F', '1F1EB'],
        ['G', '1F1EC'],
        ['H', '1F1ED'],
        ['I', '1F1EE'],
        ['J', '1F1EF'],
        ['K', '1F1F0'],
        ['L', '1F1F1'],
        ['M', '1F1F2'],
        ['N', '1F1F3'],
        ['O', '1F1F4'],
        ['P', '1F1F5'],
        ['Q', '1F1F6'],
        ['R', '1F1F7'],
        ['S', '1F1F8'],
        ['T', '1F1F9'],
        ['U', '1F1FA'],
        ['V', '1F1FB'],
        ['W', '1F1FC'],
        ['X', '1F1FD'],
        ['Y', '1F1FE'],
        ['Z', '1F1FF']
    ]);

    /**
     * Returns the hex unicode for a region code.
     */
    public static getEmojiByRegionCode(chars: string): string {
        
        var hexCodes: string[] = [];

        for (let char of chars) {
            if (!this.regionalIndicatorSymbolLetters.has(char)) {
                throw new Error('Could not parse region letter to hex code');
            } else {
                hexCodes.push(this.regionalIndicatorSymbolLetters.get(char) as string);
            }
        }

        return String.fromCodePoint(...hexCodes.map(hexCode => parseInt(hexCode, 16)));
    }

    /**
     * Returns the hex unicode for a codepoint.
     */
    public static getEmojiByCodepoint(codepoints: string[]): string {
        return String.fromCodePoint(...codepoints.map(codepoint => parseInt(codepoint, 16)));
    }
}
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { useStyletron } from "baseui";
import { FaStar } from "react-icons/fa";
import { StarRating } from "baseui/rating";
import { SessionType } from "../../models/SessionType";
import { useSessionEmoji } from "../hooks/useSessionEmoji";
import { useSessionRating } from "../hooks/useSessionRating";

export interface SessionRatingCardProps {
    grandPrixId: number;
    sessionType: SessionType;
    aggregatedRating: number;
    userRating?: number;
    count: number;
    onSessionRatingChange: (gpId: number, sessionType: SessionType, updatedScore: number) => void;
    styleOverrides?: CSSProperties;
}

export const SessionRatingCard = (props: SessionRatingCardProps) => {
    const [css, theme] = useStyletron();
    const sessionEmojiHexcode = useSessionEmoji(props.sessionType);
    
    return (
        <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px',
                ...props.styleOverrides
            }}
            className={css({
                // ...theme.typography,
                borderRadius: theme.borders.radius300,
                backgroundColor: theme.colors.mono500
            })}>
            <div style={{ width: '100%' }}>
                <span className={css({
                    ...theme.typography.LabelMedium,
                    color: theme.colors.contentPrimary
                })}>
                    {props.sessionType}&nbsp;{sessionEmojiHexcode}
                </span>
            </div>
            <div style={{ width: '100%' }}>
                <span style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                    className={css({
                        ...theme.typography.LabelMedium,
                        color: theme.colors.contentPrimary
                    })}>
                    {props.aggregatedRating}
                    <FaStar size={14} style={{
                        paddingLeft: '6px',
                        paddingRight: '6px'
                    }} />
                    &#40;{props.count}&#41;
                </span>
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end'
            }}>
                <span className={css({
                    ...theme.typography.LabelMedium,
                    color: theme.colors.contentPrimary
                })}>
                    <StarRating onChange={data => props.onSessionRatingChange(props.grandPrixId, props.sessionType, data.value)}
                        value={props.userRating}
                        size={14}/>
                </span>
            </div>
        </div>
    );
}

export default SessionRatingCard;
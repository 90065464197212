import { useCallback } from "react"

/**
 * Returns a vertical margin (because vertical margins can overlap) depending on the position of the item
 * within the list.
 * @param verticalMargin Desired vertical margin
 * @param idx index of the item in the list
 * @param arrLength size of the list
 * @returns Properties containing marginBottom
 */
export class CssUtilities {
    static useBetweenListMargin(verticalMargin: number, idx: number, arrLength: number): {} {
        return idx < arrLength - 1 ? {
                marginBottom: `${verticalMargin}px`
            } : {}
    }
}
import { useStyletron } from "baseui";
import { StarRating } from "baseui/rating";
import { useMemo } from "react";
import { FaStar } from "react-icons/fa";
import { EmojiMapper } from "../../../helpers/EmojiMapper";
import { SessionType } from "../../../models/SessionType";
import { useSessionEmoji } from "../../hooks/useSessionEmoji";
import { useSessionRating } from "../../hooks/useSessionRating";
import { SessionRatingCardProps } from "../SessionRatingCard";

export const SessionRatingCardMobile = (props: SessionRatingCardProps) => {
    const [css, theme] = useStyletron();
    const sessionEmojiHexcode = useSessionEmoji(props.sessionType);

    const [rating, count, handleRating] = useSessionRating({
        grandPrixId: props.grandPrixId,
        sessionType: props.sessionType
    });

    return (<div style={{
        ...props.styleOverrides,
        padding: '14px'
    }}
        className={css({
            backgroundColor: theme.colors.backgroundTertiary,
            borderRadius: theme.borders.radius300,
        })}>
        <span className={css({
            ...theme.typography.LabelMedium,
            color: theme.colors.contentPrimary
        })}>
            {props.sessionType}&nbsp;{sessionEmojiHexcode}
        </span>
        <div style={{
            borderTopColor: theme.colors.contentTertiary,
            width: '100%',
            marginTop: '14px',
            marginBottom: '14px',
            borderTopWidth: '1px',
            borderTopStyle: 'solid'
        }}>
        </div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '8px'
        }}>
            <span className={css({
                ...theme.typography.LabelMedium,
                color: theme.colors.contentPrimary
            })}>gprating</span>
            <span style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
                className={css({
                    ...theme.typography.LabelMedium,
                    color: theme.colors.contentPrimary
                })}>
                {props.aggregatedRating}
                <FaStar size={14} style={{
                    paddingLeft: '6px',
                    paddingRight: '6px'
                }} />
                &#40;{props.count}&#41;
            </span>
        </div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <span className={css({
                ...theme.typography.LabelMedium,
                color: theme.colors.contentPrimary
            })}>tap to rate:</span>
            <StarRating onChange={data => props.onSessionRatingChange(props.grandPrixId, props.sessionType, data.value)}
                value={props.userRating}
                size={18} />
        </div>

    </div>)
}

export default SessionRatingCardMobile;
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import "./App.css";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, createDarkTheme, createLightTheme, useStyletron } from "baseui";
import { Navigation } from "./components/Navigation";
import { GrandPrix } from "./models/GrandPrix";
import Content from "./components/Content";

import { GrandPrixStore } from "./grandprix/GrandPrixStore";
import { AxiosResponse } from "axios";
import ContentV2 from "./components/v2/ContentV2";
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { MdDarkMode } from 'react-icons/md';
import { AiTwotoneExperiment } from 'react-icons/ai';
import { SessionType, SUPPORTED_SESSION_TYPES } from "./models/SessionType";
import { GrandPrixRating } from "./models/persistence/GrandPrixRating";
import { Score } from "./models/Score";
import { GrandPrixConfig } from "./configuration/GrandPrixConfig";
import { v4 as uuidv4 } from 'uuid';

import produce, { enableMapSet } from "immer";

const engine = new Styletron();
const USER_ID: string = 'user_id';

/**
 * Immer configurations
 */
enableMapSet();

function App() {

  const [css, theme] = useStyletron();

  const rootStyle = useMemo((): CSSProperties => {
    return {
      display: "flex",
      flexFlow: "column",
      height: "100vh",
    }
  }, [theme]);

  const [gps, setGp] = useState<any>(undefined);

  // Feature Toggles
  const [isDarkModeEnabled, enableDarkMode] = React.useState(true);

  useEffect(() => {
    if (gps != null && gps != undefined) {
      return;
    }

    if (!localStorage.getItem(USER_ID)) {
      'user_id not found, creating a new one'
      const userId = uuidv4();
      localStorage.setItem(USER_ID, userId);
    }

    const userId = localStorage.getItem(USER_ID) as string;
    console.log(USER_ID, userId);
  
    GrandPrixStore.getGrandPrixRatings(0, 22, userId).then((res) => {
      console.log('fetched new ratings');

      for (const key in res.session_ratings) {
        console.log('user_rating[', key);
      }

      const grandPrixes: GrandPrix[] = [];
      for (const gpIdStr in res.aggregated_ratings) {
        const gpId = parseInt(gpIdStr);

        if (!GrandPrixConfig.get(gpId.toString())?.enabled) {
          continue;
        }

        const sessionRating = new Map<SessionType, Score>();
        const userRatings = new Map<SessionType, number>();

         // map sessionTimes by SessionType
         let sessionTimes: any;
         const sessionTimesCfg = GrandPrixConfig.get(gpId.toString())?.sessionTimes;
         const hasSprint = (sessionTimesCfg?.length ?? 0) > 2;
         if (hasSprint) {
           sessionTimes = [[SessionType.Qualifying, null], [SessionType.Sprint, null], [SessionType.Race, null]];
         } else {
           sessionTimes = [[SessionType.Qualifying, null], [SessionType.Race, null]];
         }
 
         sessionTimesCfg?.forEach((sessionTime, idx) => sessionTimes[idx][1] = sessionTime);
 
         // Transformation of session and user ratings to GrandPrix interface
         for (let sessionType of SUPPORTED_SESSION_TYPES) {
           const sessionKey = sessionType.toString().toLowerCase();
 
           if (!hasSprint && sessionType == SessionType.Sprint) {
             continue;
           }
 
           console.log( res.session_ratings[gpIdStr]?.[sessionKey]);
 
           // Computing average score
           const sum = res.aggregated_ratings[gpIdStr][sessionKey].rating_sum ?? 0;
           const count =res.aggregated_ratings[gpIdStr][sessionKey].rating_count ?? 0;
           const userRating = res.session_ratings[gpIdStr]?.[sessionKey] ?? 0;
 
           const score: Score = {
             score: count != 0 ? Math.round(sum * 100.0 / count) / 100 : 0, // x1.0 to make one of the numbers a float
             count: count
           }
 
           sessionRating.set(sessionType, score);
           userRatings.set(sessionType, userRating)
         }
 
         sessionTimes = new Map(sessionTimes);
 
         const grandPrix: GrandPrix = {
           id: gpId,
           displayName: GrandPrixConfig.get(gpId.toString())?.displayName ?? '',
           regionCode: GrandPrixConfig.get(gpId.toString())?.regionCode ?? '',
           sessionRatings: sessionRating,
           userRatings: userRatings,
           sessionTimes: sessionTimes
         };

         grandPrixes.push(grandPrix);
      }

        setGp(grandPrixes);
      }).catch((e) => {
        console.log(e)
      });
  }, [gps/*gp <=Commenting this out to not trigger an immediate read when a write is made */]);

  const onSessionRatingChange = useCallback((gpId: number, sessionType: SessionType, updatedScore: number) => {
      const userId = localStorage.getItem(USER_ID) as string;

      GrandPrixStore.putSessionRating(userId, gpId, sessionType, updatedScore);

      const nextState = produce(gps, (draft: GrandPrix[]) => {
        const hasRated = gps[gpId]?.userRatings.has(sessionType) ?? false;
        const countIncrement = hasRated ? 0 : 1;
        draft[gpId].userRatings?.set(sessionType, updatedScore);

        return draft;
      });

      setGp(nextState);
    }, [gps]);

  const darkTheme = createDarkTheme({}, {
    breakpoints: {
      small: 600, 
      // medium: 800,
      // large: 1200
    }
  });
  const lightTheme = createLightTheme({}, {
    breakpoints: {
      small: 600, 
      // medium: 800,
      // large: 1200
    }
  });

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={isDarkModeEnabled ? darkTheme : lightTheme}>
        <div style={rootStyle}>
          <Navigation isDarkMode={ isDarkModeEnabled } />
            <>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}>
                <Checkbox
                  checked={isDarkModeEnabled}
                  onChange={e => {
                    enableDarkMode(e.currentTarget.checked);
                  }}
                  checkmarkType={STYLE_TYPE.toggle_round}
                >
                  <span style={{
                    display: 'flex',
                    alignItems: 'center'
                    }}>
                      Dark Mode
                      <MdDarkMode size={18} style={{ paddingLeft: '4px', paddingRight: '4px' }}/>
                  </span>
                </Checkbox>
              </div>
              <ContentV2 grandPrixes={gps ? gps : []} onSessionRatingChange={onSessionRatingChange}/>
            </>
          <div style={{ height: "100%", flex: "1 1 auto" }}></div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;

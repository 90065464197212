import { useStyletron } from "baseui";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { convertCompilerOptionsFromJson } from "typescript";
import { EmojiMapper } from "../../helpers/EmojiMapper";

const MILLIS_2_DAYS = 172800000;

export interface GrandPrixLabelProps {
    name: string;
    regionCode: string;
    dateStart: Date;
    dateEnd: Date;
    styleOverrides?: CSSProperties;
}

export const GrandPrixLabel = (props: GrandPrixLabelProps) => {
    const [css, theme] = useStyletron();

    // const dateStart = useMemo(() => props.dateStart ? 
    //     props.dateStart : 
    //     new Date(props.dateEnd.getTime() - MILLIS_2_DAYS), [props]);

    /**
     * Formats to {Month Date} e.g. October 5th
     */
    const getShortDateString = useCallback((date: Date) => {
        return date
            .toLocaleString(undefined, {
                month: 'long',
                day: 'numeric'
            });
    }, []);

    const getEndDateString = useCallback((startDate: Date, endDate: Date) => {
        if (startDate.getUTCMonth() === endDate.getUTCMonth()) {
            return endDate.getDay().toString();
        } else {
            return getShortDateString(endDate);
        }
    }, []);

    return (
        <div style={props.styleOverrides}>
            <div className={css({
                ...theme.typography.LabelLarge,
                color: theme.colors.contentPrimary
            })}>
                {props.name} {EmojiMapper.getEmojiByRegionCode(props.regionCode)}
            </div>
            <div className={css({
                ...theme.typography.LabelMedium,
                color: theme.colors.contentTertiary
            })}
                style={{paddingTop: '8px'}}>
                {`${getShortDateString(props.dateStart)} - ${getShortDateString(props.dateEnd)}`}
            </div>
        </div>
    )

}

export default GrandPrixLabel;
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { SessionRating } from "../models/api/SessionRating";
import { GrandPrix } from "../models/GrandPrix";
import { GrandPrixRating } from "../models/persistence/GrandPrixRating";
import { SessionType } from "../models/SessionType";

export class GrandPrixStore {
    private static fetchGetApiKey(): string {
        return 'pIOPIZ9BlC9NTkiQz0S2B7dRQXM6xw2F8pgKKIA1';
    }

    private static fetchPutApiKey() : string {
        return 'ZsYV7ArrxO1CZ2kKyJ8R15M5tBb9EBIUcGmelyZe'
    }

    static async getGrandPrixRatings(gpIdMin: number, gpIdMax: number, userId?: string): Promise<GrandPrixRating> {
        var requestUrl = new URL('https://j1y2mx6os1.execute-api.us-east-1.amazonaws.com/session-rating');
        const reqConfig: AxiosRequestConfig = {
            headers: {
                'X-Api-Key': GrandPrixStore.fetchGetApiKey(),
                'Content-Type': 'application/json',
            },
        }

        requestUrl.searchParams.append('gp_id_min', gpIdMin.toString())
        requestUrl.searchParams.append('gp_id_max', gpIdMax.toString())

        if (userId && userId != '') {
            requestUrl.searchParams.append('user_id', userId)
        }
    
        const response: AxiosResponse<GrandPrixRating> = await axios.get<GrandPrixRating>(requestUrl.toString(), reqConfig);

        return response.data;
    }
    
    static async putSessionRating(userId: string, gpId: number, sessionType: SessionType, rating: number): Promise<GrandPrixRating> {
        const requestUrl = new URL('https://j1y2mx6os1.execute-api.us-east-1.amazonaws.com/session-rating');
        const body: SessionRating = {
            user_id: userId,
            gp_id: gpId,
            session_type: sessionType.toString().toLowerCase(),
            rating: rating
        }

        const reqConfig: AxiosRequestConfig = {
            headers: {
                'X-Api-Key': GrandPrixStore.fetchPutApiKey(),
                'Content-Type': 'application/json',
            },
        }

        const response = await axios.post(requestUrl.toString(), body, reqConfig);

        if (response.status >= 300) {
            throw new Error('Could not put rating successfully.');
        }

        return response.data;
    }

    static async getGrandPrixes(): Promise<GrandPrix[]> {
        const reqConfig: AxiosRequestConfig = {
            headers: {
                'X-Api-Key': GrandPrixStore.fetchGetApiKey(),
                'Content-Type': 'application/json',
            },
        }
    
        const response = await axios.get('https://umko0d8zae.execute-api.us-east-1.amazonaws.com/default/getGrandPrixes', reqConfig);

        return response.data;
    }

    /** @deprecated */
    static async addRatingForGrandPrix(grandPrixId: number, rating: number): Promise<string> {
        const body = {
            'targetId': grandPrixId,
            'rating': rating
        }

        const reqConfig: AxiosRequestConfig = {
            headers: {
                'X-Api-Key': GrandPrixStore.fetchPutApiKey(),
                'Content-Type': 'application/json',
            },
        }

        const response = await axios.put('https://6gdg3ezaai.execute-api.us-east-1.amazonaws.com/default/updateGrandPrix',
            body, reqConfig);

        if (response.status >= 300) {
            throw new Error('Could not put rating successfully.');
        }

        return response.data;
    }
}

import { useStyletron } from "baseui";
import { CSSProperties, useEffect, useMemo } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { CssUtilities } from "../../../helpers/CssUtilities";
import { GrandPrix } from "../../../models/GrandPrix"
import { Score } from "../../../models/Score";
import { SessionType } from "../../../models/SessionType";
import GrandPrixLabel from "../GrandPrixLabel"
import SessionRatingCardMobile from "./SessionRatingCardMobile";

export interface GrandPrixCard {
    isOpened: boolean;
    toggleOpen: () => void; 
    grandPrix: GrandPrix;
    onSessionRatingChange: (gpId: number, sessionType: SessionType, updatedScore: number) => void;
    styleOverrides?: CSSProperties;
}

export const GrandPrixCardMobile = (props: GrandPrixCard) => {
    const [css, theme] = useStyletron();

    // Mapping sessions to an array
    const sessionsArr = useMemo(() => props.grandPrix.sessionRatings ?
        Array.from(props.grandPrix.sessionRatings) : [], [props.grandPrix.sessionRatings]);

    const showRatingCards = useMemo(() => props.isOpened ?
        { display: 'block' } : { display: 'none' }, [props.isOpened]);

    return (
        <div style={{ ...props.styleOverrides }}
            className={css({
                backgroundColor: theme.colors.backgroundSecondary,
                borderRadius: theme.borders.radius300
            })}>
            <div style={{ overflow: 'auto' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer'
                }}
                    onClick={props.toggleOpen}>
                    <GrandPrixLabel name={props.grandPrix.displayName}
                        regionCode={props.grandPrix.regionCode}
                        dateStart={props.grandPrix.sessionTimes.get(SessionType.Qualifying) as Date}
                        dateEnd={props.grandPrix.sessionTimes.get(SessionType.Race) as Date}
                        styleOverrides={{
                            padding: '14px',
                            // marginBottom: '20px'
                        }} />
                    { props.isOpened ? 
                        <AiOutlineMinus size={20}
                            color={theme.colors.contentPrimary}
                            style={{ padding: '14px'}} /> :
                        <AiOutlinePlus size={20}
                            color={theme.colors.contentPrimary}
                            style={{ padding: '14px'}} />
                    }
                </div>
                <div style={{...showRatingCards}}>
                    {sessionsArr.map((sessionEntry, idx) =>
                        <SessionRatingCardMobile sessionType={sessionEntry[0]}
                            grandPrixId={props.grandPrix.id}
                            aggregatedRating={sessionEntry[1].score}
                            userRating={props.grandPrix.userRatings?.get(sessionEntry[0])}
                            count={sessionEntry[1].count}
                            onSessionRatingChange={props.onSessionRatingChange}
                            styleOverrides={{
                                margin: '14px'
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default GrandPrixCardMobile;
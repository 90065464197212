const gpConfig = {
    0: {
        displayName: 'Bahrain GP',
        regionCode: 'BH',
        enabled: true,
        sessionTimes: [
            new Date('2023-03-04Z15:00:00'),
            new Date('2023-03-05Z15:00:00')
        ]
    },
    1: {
        displayName: 'Saudi Arabia GP',
        regionCode: 'SA',
        enabled: true,
        sessionTimes: [
            new Date('2023-03-18Z17:00:00'),
            new Date('2023-03-19Z17:00:00')
        ]
    },
    2: {
        displayName: 'Australian GP',
        regionCode: 'AU',
        enabled: true,
        sessionTimes: [
            new Date('2023-04-01Z06:00:00'),
            new Date('2023-04-02Z12:00:00')
        ]
    },
    3: {
        displayName: 'Azerbaijan GP',
        regionCode: 'AZ',
        enabled: true,
        sessionTimes: [
            new Date('2023-04-28Z10:30:00'),
            new Date('2023-04-29Z14:30:00'),
            new Date('2023-04-30Z12:30:00')
        ]
    },
    4: {
        displayName: 'Miami GP',
        regionCode: 'US',
        enabled: true,
        sessionTimes: [
            new Date('2023-05-06Z21:00:00'),
            new Date('2023-05-07Z20:30:00')
        ]
    },
    5: {
        displayName: 'Emilia Romagna GP',
        regionCode: 'IT',
        enabled: false,
        sessionTimes: [
            new Date('2023-05-20Z15:00:00'),
            new Date('2023-05-21Z14:00:00')
        ]
    },
    6: {
        displayName: 'Monaco GP',
        regionCode: 'MC',
        enabled: true,
        sessionTimes: [
            new Date('2023-05-27Z15:00:00'),
            new Date('2023-05-28Z14:00:00')
        ]
    },
    7: {
        displayName: 'Spanish GP',
        regionCode: 'ES',
        enabled: true,
        sessionTimes: [
            new Date('2023-06-03Z15:00:00'),
            new Date('2023-06-04Z14:00:00')
        ]
    },
    8: {
        displayName: 'Canadian GP',
        regionCode: 'CA',
        enabled: true,
        sessionTimes: [
            new Date('2023-06-17Z21:00:00'),
            new Date('2023-06-18Z19:00:00')
        ]
    },
    9: {
        displayName: 'Austrian GP',
        regionCode: 'AT',
        enabled: true,
        sessionTimes: [
            new Date('2023-07-01Z15:30:00'),
            new Date('2023-07-02Z14:00:00')
        ]
    },
    10: {
        displayName: 'British GP',
        regionCode: 'GB',
        enabled: true,
        sessionTimes: [
            new Date('2023-07-08Z15:00:00'),
            new Date('2023-07-09Z15:00:00')
        ]
    },
    11: {
        displayName: 'Hungarian GP',
        regionCode: 'HU',
        enabled: true,
        sessionTimes: [
            new Date('2023-07-22Z15:00:00'),
            new Date('2023-07-23Z14:00:00')
        ]
    },
    12: {
        displayName: 'Belgian GP',
        regionCode: 'BE',
        enabled: false,
        sessionTimes: [
            new Date('2023-07-29Z15:30:00'),
            new Date('2023-07-30Z14:00:00')
        ]
    },
    13: {
        displayName: 'Dutch GP',
        regionCode: 'NL',
        enabled: false,
        sessionTimes: [
            new Date('2023-08-26Z14:00:00'),
            new Date('2023-08-27Z14:00:00')
        ]
    },
    14: {
        displayName: 'Italian GP',
        regionCode: 'IT',
        enabled: false,
        sessionTimes: [
            new Date('2023-09-02Z15:00:00'),
            new Date('2023-09-03Z14:00:00')
        ]
    },
    15: {
        displayName: 'Singapore GP',
        regionCode: 'SG',
        enabled: false,
        sessionTimes: [
            new Date('2023-09-16Z14:00:00'),
            new Date('2023-09-17Z13:00:00')
        ]
    },
    16: {
        displayName: 'Japanese GP',
        regionCode: 'JP',
        enabled: false,
        sessionTimes: [
            new Date('2023-09-23Z07:00:00'),
            new Date('2023-09-24Z06:00:00')
        ]
    },
    17: {
        displayName: 'Qatar GP',
        regionCode: 'QA',
        enabled: false,
        sessionTimes: [
            new Date('2023-10-06Z18:00:00'),
            new Date('2023-10-07Z18:30:00'),
            new Date('2023-10-08Z18:00:00')
        ]
    },
    18: {
        displayName: 'United States GP',
        regionCode: 'US',
        enabled: false,
        sessionTimes: [
            new Date('2023-10-20Z22:00:00'),
            new Date('2023-10-21Z23:30:00'),
            new Date('2023-10-22Z20:00:00')
        ]
    },
    19: {
        displayName: 'Mexican GP',
        regionCode: 'MX',
        enabled: false,
        sessionTimes: [
            new Date('2023-10-28Z22:00:00'),
            new Date('2023-10-29Z20:00:00')
        ]
    },
    20: {
        displayName: 'Brazilian GP',
        regionCode: 'BR',
        enabled: false,
        sessionTimes: [
            new Date('2023-11-03Z18:00:00'),
            new Date('2023-11-04Z18:30:00'),
            new Date('2023-11-05Z17:00:00')
        ]
    },
    21: {
        displayName: 'Las Vegas GP',
        regionCode: 'US',
        enabled: false,
        sessionTimes: [
            new Date('2023-11-18Z08:00:00'),
            new Date('2023-11-19Z06:00:00')
        ]
    },
    22: {
        displayName: 'Abu Dhabi GP',
        regionCode: 'AE',
        enabled: false,
        sessionTimes: [
            new Date('2023-11-25Z14:00:00'),
            new Date('2023-11-26Z13:00:00')
        ]
    }
};

export const GrandPrixConfig = new Map(Object.entries(gpConfig));